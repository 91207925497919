<template>
  <div>
    <v-btn class="mt-6" color="primary" :to="{ name: 'new_magazine' }">
      {{ translate("New Magazine") }}
    </v-btn>
    <v-card>
      <v-card-title>
        {{ translate("Magazines") }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-simple-table fixed-header>
        <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="20" class="elevation-1">
          <template v-slot:item.actions="{ item }">
            <router-link :to="{
              name: 'edit_magazine',
              query: {
                id: item.id,
                name: item.name,
                phone_number: item.phone_number,
                lat: item.lat,
                lng: item.lng,
                address: item.address,
                town: item.town,
                allow_stock_override: item.allow_stock_override,
              },
            }">
              <v-icon style="color: var(--v-warning-base)" small> mdi-pencil</v-icon>
            </router-link>
            <v-dialog :retain-focus="false" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click.stop="
                  editing_item = JSON.parse(JSON.stringify(item));
                item.delete_dialog = true;
                " small style="color: var(--v-error-base)" v-bind="attrs" v-on="on">
                  mdi-delete
                </v-icon>
              </template>

              <v-card>
                <v-card-title class="text-h5">deleting {{ item.name }}</v-card-title>
                <v-card-text>
                  {{ translate("Are you sure want to delete this item") }}?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                    translate("Cancel")
                  }}</v-btn>
                  <v-btn color="primary" @click="handleDelete(item.id)">{{
                    translate("OK")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { mdiClipboardPlusOutline, mdiDotsVertical } from "@mdi/js";
import { deleteMagazine, getMagazines } from "@/ApiManager";

export default {
  setup() {
    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiDotsVertical,
      },
    };
  },
  data() {
    return {
      search: "",
      delete_dialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Phone Number"), value: "phone_number" },
        { text: this.translate("Actions"), value: "actions" },
      ],
      items: [],
    };
  },
  methods: {
    handleEdit(id) {
      console.log("edited");
    },
    handleDelete(id) {
      this.delete_dialog = false;
      deleteMagazine(id).then((response) => {
        this.items = response.data;
      });
      console.log("deleted");
    },
  },
  created() {
    this.$store.state.loading = true;
    getMagazines().then((response) => {
      this.items = response;
      this.$store.state.loading = false;
    });
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
