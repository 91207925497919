var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","to":{ name: 'new_magazine' }}},[_vm._v(" "+_vm._s(_vm.translate("New Magazine"))+" ")]),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Magazines"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-simple-table',{attrs:{"fixed-header":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":20},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'edit_magazine',
            query: {
              id: item.id,
              name: item.name,
              phone_number: item.phone_number,
              lat: item.lat,
              lng: item.lng,
              address: item.address,
              town: item.town,
              allow_stock_override: item.allow_stock_override,
            },
          }}},[_c('v-icon',{staticStyle:{"color":"var(--v-warning-base)"},attrs:{"small":""}},[_vm._v(" mdi-pencil")])],1),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-error-base)"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.editing_item = JSON.parse(JSON.stringify(item));
              item.delete_dialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("deleting "+_vm._s(item.name))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.translate("Are you sure want to delete this item"))+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_vm._v(_vm._s(_vm.translate("OK")))]),_c('v-spacer')],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }